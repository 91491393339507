import { DateTime } from 'luxon'
import { deliveryTableDate } from '@utils/functions/customDates'
import { useTranslation } from 'react-i18next'
import { Box, Typography, styled } from '@mui/material'

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  minWidth: '80px',
}))

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
}))

const InfoRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '8px',
})

const FuelPoint = styled(Box)(({ theme }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  background: theme.palette.primary.main,
}))

const FuelPointContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

type Props = {
  date: DateTime | undefined
  km: number | undefined
  fuel: number | undefined
  title: string
}

const VehicleDetails = ({ date, km, fuel, title }: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ width: '100%', textTransform: 'capitalize' }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        {title}
      </Typography>

      <InfoRow>
        <InfoLabel>{t('common.shared.date')} :</InfoLabel>
        <InfoValue>{date ? deliveryTableDate(date) : 'not-available'}</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoLabel>{t('common.shared.mileage')} :</InfoLabel>
        <InfoValue>{km !== -1 ? `${km} km` : 'not-available'}</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoLabel>{t('common.shared.fuel')} :</InfoLabel>
        <FuelPointContainer>
          {fuel && fuel >= 0
            ? [...Array<never>(8)].map((_, i) => (
                <FuelPoint key={i} sx={{ opacity: fuel > i ? 1 : 0.25 }} />
              ))
            : ' not-available'}
        </FuelPointContainer>
      </InfoRow>
    </Box>
  )
}

export default VehicleDetails
