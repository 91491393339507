import React, { useState } from 'react'

import DownloadIcon from '../../icons/download.icon'
import styles from '../index.module.scss'
import {
  Accordion,
  AccordionSummary,
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@mui/icons-material/FilterList'
import MapsAndTableToggle from '@components/common/MapsAndTableToggle'
import { useUserContext } from '@/contexts/UserContext/index.context'
import { UserRole } from '@types_def/models/user.type'
type Props = {
  total: number
  filters?: JSX.Element
  exportHandler?: () => void
  filtersSummary?: JSX.Element
}
function TableToolbar({ total, filters, exportHandler, filtersSummary }: Props) {
  const { t } = useTranslation()
  const { userState } = useUserContext()
  const userRole = userState!['custom:role'] as UserRole
  const totalPhrase =
    total === 1 ? `${total} ${t('common.shared.line')}` : `${total} ${t('common.shared.lines')}`
  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => setExpanded(!expanded)

  const url = new URL(window.location.href)

  return (
    <div className={styles.deliveryTableHeader}>
      <Box display='flex' alignItems='center' gap={2} width={'150px'}>
        {Boolean(total) && (
          <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
            {totalPhrase}
          </Typography>
        )}
        {Boolean(exportHandler) && userRole !== UserRole.CLIENT && (
          <Tooltip title={t('common.actions.export-table')}>
            <IconButton onClick={exportHandler} sx={{}} color='primary'>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {filters ? (
        <div className={styles.deliveryTableHeaderFilter}>
          <Accordion expanded={false}>
            <AccordionSummary
              expandIcon={
                <IconButton onClick={handleExpand}>
                  <FilterListIcon />
                </IconButton>
              }
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <Typography variant='body1'>Filters &nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ flexWrap: 'wrap' }}>
                    {filtersSummary}
                  </Stack>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
          <Modal
            open={expanded}
            onClose={handleExpand}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Container
              maxWidth='lg'
              component={Paper}
              elevation={2}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: 4,
              }}
            >
              {React.cloneElement(filters, {
                handleExpand,
              })}
            </Container>
          </Modal>

          {/* {filters?.map((filter, index) => (
          <Fragment key={`table-filter-${index}`}>{filter}</Fragment>
        ))} */}
        </div>
      ) : null}
      {url?.pathname?.includes('delivery-table/ongoing') ? <MapsAndTableToggle /> : null}
    </div>
  )
}

export default TableToolbar
