import { Box, styled } from '@mui/material'
import ArrowIcon from '@components/common/icons/arrow.icon'

const EndPointsSeperator = styled(Box)({
  position: 'relative',
  width: 'fit-content',
})

const EndPointsSeperatorLine = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 1,
  background: '#000',
})

const SeperatorArrowWrapper = styled(Box)({
  position: 'absolute',
  left: -9,
  top: '50%',
  transform: 'translateY(-50%)',
})

const SeperatorArrow = styled(Box)({
  minWidth: 'calc(22px + 0.5rem)',
  width: 'fit-content',
  padding: '0.5rem 0',
  background: '#c9ccd5',
  '& svg *': {
    fill: '#000',
    marginRight: 0,
  },
})

const MiddleArrow = () => (
  <EndPointsSeperator>
    <EndPointsSeperatorLine />
    <SeperatorArrowWrapper>
      <SeperatorArrow>
        <ArrowIcon />
      </SeperatorArrow>
    </SeperatorArrowWrapper>
  </EndPointsSeperator>
)

export default MiddleArrow
