type TLoadingIcon = {
  stroke?: string
  width?: string
  height?: string

}
const LoadingIcon = ({stroke = "#ffffff", width = "16px", height = "16px"}: TLoadingIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{ display: 'block', shapeRendering: 'auto', margin:0 }}
    width={width}
    height={height}
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
  >
    <circle
      cx='50'
      cy='50'
      r='32'
      strokeWidth='8'
      stroke={stroke}
      strokeDasharray='50.26548245743669 50.26548245743669'
      fill='none'
      strokeLinecap='round'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        repeatCount='indefinite'
        dur='1s'
        keyTimes='0;1'
        values='0 50 50;360 50 50'
      ></animateTransform>
    </circle>
  </svg>
)

export default LoadingIcon