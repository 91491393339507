import { Grid, Modal, styled } from '@mui/material'
import { PhotoModalRootProps } from './types'

const ModalContent = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  minWidth: '1000px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
}))

export const PhotoModalRoot = ({ open, onClose, children }: PhotoModalRootProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent container spacing={2} justifyContent='center'>
        {children}
      </ModalContent>
    </Modal>
  )
}
