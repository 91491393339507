import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { DamagedCarImages, Vehicle } from '@/types/models/vehicle.types'
import { useUpdateVehicle } from '../../DeliveryFormV2/Querys'

type RenderProps = {
  getRootProps: () => React.HTMLAttributes<HTMLDivElement>
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  loading: boolean
  isSuccess: boolean
  handleImageClick: () => void
  error?: string
}

type Props = {
  tooltip: string
  imagePos: keyof DamagedCarImages
  url?: string
  baseImageKey: string
  viewOnly?: boolean
  vehicle: Vehicle
  _key: 'imagesBefore' | 'imagesAfter'
  extraImagePos?: string
  render: (props: RenderProps) => React.ReactNode
}

const CarImageUpload = ({
  imagePos,
  url,
  baseImageKey,
  vehicle,
  _key,
  extraImagePos,
  render,
}: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { mutate: updateVehicle, isSuccess, isPending } = useUpdateVehicle()

  const onDropRejected = useCallback(() => {
    setError('Only image files are allowed')
    toast.info('Only image files are allowed', { position: 'bottom-right' })
  }, [])

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true)
      setError(undefined)
      try {
        const file = acceptedFiles[0]
        const fileName = file.name
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1)

        const formData = new FormData()
        formData.append('file', file)

        const key = baseImageKey
          .replace('IMAGE_POSITION', imagePos.toLowerCase())
          .replace('FILE_EXTENSION', fileExtension)
        formData.append('key', key)

        const response = await axios.post('/s3/upload-files', formData)
        if (response.status !== 201) {
          throw new Error('Error uploading image to aws')
        }
        updateVehicle({
          ...vehicle,
          [_key]: {
            ...(vehicle[_key] ? vehicle[_key] : {}),
            [imagePos]:
              imagePos === 'extraImages' && extraImagePos
                ? [
                    ...(vehicle[_key]?.extraImages || []),
                    {
                      [extraImagePos]: response.data.Location,
                    },
                  ]
                : response.data.Location,
          },
        })
      } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred'
        setError(errorMessage)
        console.error(error)
        toast.error(t('network.errors.unknown'), { position: 'bottom-right' })
      } finally {
        setLoading(false)
      }
    },
    [baseImageKey, imagePos, t, vehicle, _key, updateVehicle, extraImagePos],
  )

  const handleImageClick = () => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
    onDropRejected,
    disabled: loading,
    maxSize: 5 * 1024 * 1024, // 5MB
  })

  return render({
    getRootProps,
    getInputProps,
    loading: loading || isPending,
    isSuccess,
    handleImageClick,
    error,
  })
}

export default CarImageUpload
