import { QueryType } from '@types_def/common/query.types'
import { Delivery, DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'

{
  /* 
    validationDocs: true,
    selectedDriver: {
    id: true,
    fullName: true,
    phoneNumber: true,
    email: true,
    address: {
      address: true,
    },
    company: true,
  },


  wantedPrice: true,
  publicId: true,
  distance: true,
  createdAt: true,
  startCode: true,
  confirmedAt: true,
  deliveredAt: true,
  destinationCode: true,
  departedAt: true,
  finalPrice: true,
  clientInitialPrice: true,
  clientFinalPrice: true,
  outfitRating: true,
  correspondenceRating: true,
  justiceRating: true,
  takeOffRating: true,
  qualityRating: true,
  communicationRating: true,
  rated: true,


    vehicles: {
    carType: true,
    vehicleCode: true,
    toRetrieve: true,
    imagesBefore: undefined,
    imagesAfter: undefined,
    essenceBefore: true,
    essenceAfter: true,
    mileageBefore: true,
    model: true,
    marque: true,
    mileageAfter: true,
  },
    */
}

const Address = {
  postalCode: true,
  city: true,
  address: true,
  longitude: true,
  latitude: true,
  place_id: true,
  addressPrecision: true,
  country: true,
}

const commun: QueryType<Delivery> = {
  id: true,
  status: true,
  type: true,
  twoWayDeliverType: true,
  numberOfVehicles: true,

  clientFirstName: true,
  clientLastName: true,
  clientEmail: true,
  clientPhone: true,
  clientCompany: true,
  clientSiret: true,
  clientDrivingLicenceNumber: true,
  representativeFirstName: true,
  representativeLastName: true,
  representativeEmail: true,
  representativePhone: true,
  representativeCompany: true,
  representativeSiret: true,

  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,

  docs: undefined,

  fromAddress: Address,
  toAddress: Address,

  vehicles: {
    id: true,
    vin: true,
    carType: true,
    vehicleCode: true,
    toRetrieve: true,
    model: true,
    marque: true,
    type: true,
    essenceAfter: true,
    essenceBefore: true,
    mileageAfter: true,
    mileageBefore: true,
    isDelivered: true,
    imagesBefore: undefined,
    imagesAfter: undefined,
  },
  client: {
    id: true,
    assigneeFirstName: true,
    assigneeLastName: true,
    assigneeEmail: true,
    assigneePhone: true,
  },

  category: true,
  options: undefined,
  comment: true,

  clientInitialPrice: true,
  clientFinalPrice: true,
}

const DeliveryQueryFieldsByType: { [key in DeliveryType]: QueryType<Delivery> } = {
  [DeliveryType.STANDARD]: {
    ...commun,
  },
  [DeliveryType.TO_RETURN]: {
    ...commun,
  },
  [DeliveryType.TWO_WAY_DELIVERY]: {
    ...commun,
    toReturnFromAddress: Address,
    toReturnToAddress: Address,
  },
  [DeliveryType.OKM]: {
    ...commun,
  },
}

const UpdateCommun: QueryType<Delivery> = {
  id: true,
  status: true,
  type: true,
  twoWayDeliverType: true,
  numberOfVehicles: true,
  clientFirstName: true,
  clientLastName: true,
  clientEmail: true,
  clientPhone: true,
  clientCompany: true,
  clientSiret: true,
  clientDrivingLicenceNumber: true,
  representativeFirstName: true,
  representativeLastName: true,
  representativeEmail: true,
  representativePhone: true,
  representativeCompany: true,
  representativeSiret: true,
  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,
  docs: undefined,
  fromAddress: Address,
  toAddress: Address,
  vehicles: {
    vin: true,
    carType: true,
    vehicleCode: true,
    toRetrieve: true,
    model: true,
    marque: true,
    type: true,
  },
  category: true,
  options: undefined,
  comment: true,
  clientInitialPrice: true,
  clientFinalPrice: true,
}
export const UpdateDeliveryQueryFields: { [key in DeliveryType]: QueryType<Delivery> } = {
  [DeliveryType.STANDARD]: {
    ...UpdateCommun,
  },
  [DeliveryType.TO_RETURN]: {
    ...UpdateCommun,
  },
  [DeliveryType.TWO_WAY_DELIVERY]: {
    ...UpdateCommun,
    toReturnFromAddress: Address,
    toReturnToAddress: Address,
  },
  [DeliveryType.OKM]: {
    ...UpdateCommun,
  },
}

const deliverySmsQueryFields: QueryType<Delivery> = {
  smsId: true,
  smsSentAt: true,
  confirmedByCustomerAt: true,
}

const SetDeliveryQueryFields: QueryType<Delivery> = {
  startCode: true,
  destinationCode: true,
  selectedDriverId: true,
  selectedDriver: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
  },
}

const OngoingDeliveryQueryFields: QueryType<Delivery> = {
  departedAt: true,
  docs: undefined,
  vehicles: {
    ...commun.vehicles,
    imagesBefore: undefined,
    essenceBefore: true,
    mileageBefore: true,
  },
  selectedDriver: {
    firstName: true,
    lastName: true,
    id: true,
    avatar: true,
    email: true,
    phoneNumber: true,
  },
}

const DelivredDeliveryQueryFields: QueryType<Delivery> = {
  completedAt: true,
  validationDocs: undefined,
  vehicles: {
    ...OngoingDeliveryQueryFields.vehicles,
    imagesAfter: undefined,
    essenceAfter: true,
    mileageAfter: true,
  },
}

const CancelledDeliveryQueryFields: QueryType<Delivery> = {
  cancelledAt: true,
  cancelReason: undefined,
}

const DeliveryQueryFieldsByStatus: { [key in DeliveryStatus]: QueryType<Delivery> } = {
  [DeliveryStatus.REQUESTED]: {
    selectedDriver: {
      topDeals: true,
      fullName: true,
    },
  },
  [DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION]: {
    ...commun,
    ...deliverySmsQueryFields,
  },
  [DeliveryStatus.CONFIRMED_BY_CUSTOMER]: {
    ...commun,
    ...deliverySmsQueryFields,
  },
  [DeliveryStatus.CANCELLED_BY_CUSTOMER]: { ...CancelledDeliveryQueryFields },
  [DeliveryStatus.RESCHEDULED_BY_ADMIN]: {},
  [DeliveryStatus.CANCELLED_BY_ADMIN]: { ...CancelledDeliveryQueryFields },
  [DeliveryStatus.CONFIRMED]: {},
  [DeliveryStatus.WAITING_FOR_DRIVER]: {},
  [DeliveryStatus.CANCELLED_BY_MANDATORY]: { ...CancelledDeliveryQueryFields },
  [DeliveryStatus.SET]: {
    ...SetDeliveryQueryFields,
  },
  [DeliveryStatus.ALERT]: {},
  [DeliveryStatus.PICKUP_DRIVER]: {
    ...OngoingDeliveryQueryFields,

    ...SetDeliveryQueryFields,
  },
  [DeliveryStatus.PICKUP_CLIENT]: {
    ...OngoingDeliveryQueryFields,
    ...SetDeliveryQueryFields,
  },
  [DeliveryStatus.ONGOING]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },
  [DeliveryStatus.SWAP_DELIVERED]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },
  [DeliveryStatus.DELIVERED]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },
  [DeliveryStatus.SWAP_IN_TRANSIT]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },
  [DeliveryStatus.SWAP_PICKUP_CLIENT]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },
  [DeliveryStatus.SWAP_PICKUP_DRIVER]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
  },

  [DeliveryStatus.COMPLETED]: {
    ...SetDeliveryQueryFields,
    ...OngoingDeliveryQueryFields,
    ...DelivredDeliveryQueryFields,
    invoice: true,
  },
  [DeliveryStatus.SMS_SENT]: {
    ...commun,
    ...deliverySmsQueryFields,
  },
  [DeliveryStatus.CANCELLED]: CancelledDeliveryQueryFields,
}

export { DeliveryQueryFieldsByType, DeliveryQueryFieldsByStatus }
