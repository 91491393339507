import { Box, Grid, Typography, styled, Fab } from '@mui/material'
import carSchema from '@/assets/images/car-schema.jpg'
import { PhotoSectionProps, ImageConfig } from './types'
import { SchemaImageButton } from './SchemaImageButton'
import { ExtraImageButton } from './ExtraImageButton'
import { PhotoDivider } from './PhotoDivider'
import { t } from 'i18next'
import CarImageUpload from '../carImageUpload'
import { DamagedCarImages } from '@/types/models/vehicle.types'
import { Upload } from '@mui/icons-material'

const CarSchemaContainer = styled(Grid)({
  backgroundImage: `url(${carSchema})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  minHeight: '400px',
  minWidth: '300px',
})

const SectionTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  textTransform: 'capitalize',
}))

const ImageActionContainer = styled(Box)({
  position: 'relative',
  display: 'inline-block',
})

const UploadButton = styled(Fab)({
  position: 'absolute',
  top: -12,
  right: -12,
  minWidth: '30px',
  maxWidth: '30px',
  minHeight: '30px',
  maxHeight: '30px',
  zIndex: 1,
  opacity: 0.6,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    opacity: 1,
    transform: 'scale(1.1)',
  },
})

const IMAGE_POSITIONS: ImageConfig[] = [
  { key: 'topImage', tooltip: t('delivery.photos.top'), gridConfig: { xs: 12 } },
  { key: 'topLeftImage', tooltip: t('delivery.photos.top-left'), gridConfig: { xs: 6 } },
  { key: 'topRightImage', tooltip: t('delivery.photos.top-right'), gridConfig: { xs: 6 } },
  { key: 'bottomLeftImage', tooltip: t('delivery.photos.bottom-left'), gridConfig: { xs: 6 } },
  { key: 'bottomRightImage', tooltip: t('delivery.photos.bottom-right'), gridConfig: { xs: 6 } },
  { key: 'bottomImage', tooltip: t('delivery.photos.bottom'), gridConfig: { xs: 12 } },
]

const EXTRA_IMAGES: ImageConfig[] = [
  { key: 'dashboard', tooltip: t('delivery.photos.dashboard') },
  { key: 'cables', tooltip: t('delivery.photos.cables') },
]

export const PhotoSection = ({ type, vehicle, images, isAdmin = false }: PhotoSectionProps) => {
  const baseImageKey = `Cars/images${type}/${vehicle.id}-IMAGE_POSITION.FILE_EXTENSION`

  return (
    <Grid item xs={5}>
      <SectionTitle variant='h6'>
        {type === 'before' ? t('delivery.image-of-departure') : t('delivery.image-of-arrived')}
      </SectionTitle>
      <PhotoDivider />

      <CarSchemaContainer container spacing={2}>
        {IMAGE_POSITIONS.map(({ key, tooltip, gridConfig }) => {
          const imageUrl = images?.[key as keyof DamagedCarImages]
          return (
            <Grid item {...gridConfig} key={key}>
              <Box textAlign='center' sx={{ flexGrow: 1 }}>
                <CarImageUpload
                  viewOnly={!isAdmin}
                  tooltip={tooltip}
                  imagePos={key as keyof DamagedCarImages}
                  url={imageUrl as string | undefined}
                  baseImageKey={baseImageKey}
                  vehicle={vehicle}
                  _key={`images${type === 'before' ? 'Before' : 'After'}`}
                  render={({
                    getRootProps,
                    getInputProps,
                    loading,
                    isSuccess,
                    handleImageClick,
                  }) => (
                    <ImageActionContainer>
                      <SchemaImageButton
                        loading={loading}
                        isSuccess={isSuccess}
                        handleImageClick={handleImageClick}
                        hasImage={!!imageUrl}
                        tooltip={tooltip}
                      />
                      {isAdmin && (
                        <Box {...getRootProps()}>
                          <UploadButton
                            color='secondary'
                            title={`${t('common.buttons.upload')} ${tooltip}`}
                            size='small'
                          >
                            <Upload sx={{ m: 0, fontSize: 16 }} />
                          </UploadButton>
                          <input {...getInputProps()} />
                        </Box>
                      )}
                    </ImageActionContainer>
                  )}
                />
              </Box>
            </Grid>
          )
        })}
      </CarSchemaContainer>

      <PhotoDivider />

      <Typography
        variant='h6'
        component='h3'
        sx={{ textAlign: 'left', textTransform: 'capitalize' }}
      >
        {t('delivery.form.additional-photos')}
      </Typography>

      <Grid container gap={1} py={2}>
        {EXTRA_IMAGES.map(({ key, tooltip }, index) => {
          const extraImageUrl = Array.isArray(images?.extraImages)
            ? images.extraImages.find((img) => Object.keys(img)[0] === key)?.[key]
            : undefined
          const baseExtraImageKey = `Cars/extraImages/${vehicle.id}-${key}-IMAGE_POSITION.FILE_EXTENSION`

          return (
            <Grid item xs={12} key={key}>
              <CarImageUpload
                viewOnly={!isAdmin}
                tooltip={tooltip}
                imagePos='extraImages'
                extraImagePos={key}
                url={extraImageUrl}
                baseImageKey={baseExtraImageKey}
                vehicle={vehicle}
                _key={`images${type === 'before' ? 'Before' : 'After'}`}
                render={({ getRootProps, getInputProps, loading, isSuccess, handleImageClick }) => (
                  <ImageActionContainer sx={{ width: '100%' }}>
                    <ExtraImageButton
                      loading={loading}
                      isSuccess={isSuccess}
                      handleImageClick={handleImageClick}
                      image={tooltip}
                      index={index}
                      extraImageUrl={extraImageUrl}
                    />
                    {isAdmin && (
                      <Box {...getRootProps()}>
                        <UploadButton
                          color='secondary'
                          title={`${t('common.buttons.upload')} ${tooltip}`}
                          size='small'
                        >
                          <Upload sx={{ m: 0, fontSize: 16 }} />
                        </UploadButton>
                        <input {...getInputProps()} />
                      </Box>
                    )}
                  </ImageActionContainer>
                )}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
