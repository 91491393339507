import { PhotoModalRoot } from './PhotoModalRoot'
import { PhotoSection } from './PhotoSection'
import { SchemaImageButton } from './SchemaImageButton'
import { ExtraImageButton } from './ExtraImageButton'
import { PhotoDivider } from './PhotoDivider'
import { usePhotoLogic } from './usePhotoLogic'

export const VehiclePhotos = {
  Root: PhotoModalRoot,
  Section: PhotoSection,
  SchemaImage: SchemaImageButton,
  ExtraImage: ExtraImageButton,
  Divider: PhotoDivider,
  usePhotoHandlers: usePhotoLogic,
}

export * from './types'
