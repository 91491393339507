import { Button, CircularProgress, styled } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { VisibilityOff } from '@mui/icons-material'
import { ExtraImageButtonProps } from './types'

const StyledButton = styled(Button)({
  textAlign: 'left',
  fontSize: '0.8rem',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
})

export const ExtraImageButton = ({
  loading,
  isSuccess,
  handleImageClick,
  image,
  index,
  extraImageUrl,
}: ExtraImageButtonProps) => (
  <StyledButton
    onClick={handleImageClick}
    endIcon={
      loading ? (
        <CircularProgress size={16} color='inherit' />
      ) : extraImageUrl ? (
        <VisibilityIcon />
      ) : (
        <VisibilityOff sx={{ color: 'gray' }} />
      )
    }
    size='small'
    variant={extraImageUrl ? 'contained' : 'outlined'}
    color={isSuccess ? 'success' : 'primary'}
    fullWidth
    disabled={loading}
  >
    {`${index + 1}: ${image}`}
  </StyledButton>
)
