import './index.scss'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')

import App from './App'
import './i18n'
import { StrictMode } from 'react'

Sentry.init({
  dsn: 'https://2fc091f6ca7e314e07763e32f7cee7a0@o4507446379085824.ingest.de.sentry.io/4507492330307664',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.alb-mobility\.com\//,
    /^https:\/\/api-v2\.alb-mobility\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
