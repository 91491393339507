import { Button } from '@mui/material'
import { useState } from 'react'
import { Delivery } from '@/types/models/delivery.types'
import { t } from 'i18next'
import { VehiclePhotos } from '.'
import { Grid, Divider } from '@mui/material'

interface PhotoModalProps {
  delivery: Delivery
  isAdmin: boolean
  index: number
}

const PhotoModal = ({ delivery, isAdmin, index }: PhotoModalProps) => {
  const [open, setOpen] = useState(false)
  const vehicle = delivery.vehicles[index]

  if (!vehicle) {
    return null
  }

  const imageKeyBefore = `Cars/imagesBefore/${delivery.id}-${vehicle.id}-IMAGE_POSITION.FILE_EXTENSION`
  const imageKeyAfter = `Cars/imagesAfter/${delivery.id}-${vehicle.id}-IMAGE_POSITION.FILE_EXTENSION`

  return (
    <>
      <Button
        disabled={!vehicle?.imagesAfter && !vehicle?.imagesBefore}
        sx={{ my: 2 }}
        fullWidth
        variant='contained'
        color='primary'
        onClick={() => setOpen(true)}
      >
        {t('common.buttons.view-photos')}
      </Button>

      <VehiclePhotos.Root open={open} onClose={() => setOpen(false)}>
        <VehiclePhotos.Section
          type='before'
          vehicle={vehicle}
          images={vehicle?.imagesBefore}
          isAdmin={isAdmin}
          baseImageKey={imageKeyBefore}
        />

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider orientation='vertical' />
        </Grid>

        <VehiclePhotos.Section
          type='after'
          vehicle={vehicle}
          images={vehicle?.imagesAfter}
          isAdmin={isAdmin}
          baseImageKey={imageKeyAfter}
        />
      </VehiclePhotos.Root>
    </>
  )
}

export default PhotoModal
