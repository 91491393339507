import { Box, CircularProgress, Fab } from '@mui/material'
import { green } from '@mui/material/colors'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { SchemaImageButtonProps } from './types'

export const SchemaImageButton = ({
  loading,
  isSuccess,
  handleImageClick,
  hasImage,
  tooltip,
}: SchemaImageButtonProps) => (
  <Box sx={{ m: 1, position: 'relative', lineHeight: 0 }}>
    <Fab
      onClick={handleImageClick}
      color={isSuccess ? 'success' : 'primary'}
      disabled={loading || !hasImage}
      title={tooltip}
    >
      <VisibilityIcon
        sx={{
          color: hasImage ? 'inherit' : 'action.disabled',
          m: 0,
        }}
      />
    </Fab>

    {loading && (
      <CircularProgress
        color='inherit'
        size={68}
        sx={{
          color: green[500],
          position: 'absolute',
          top: -6,
          left: -6,
          zIndex: 1,
          '& svg': {
            m: 0,
          },
        }}
      />
    )}
  </Box>
)
