import { Box, styled } from '@mui/material'
import { type Delivery } from '@types_def/models/delivery.types'
import MiddleArrow from './Arrow'
import VehicleDetails from './VehicleDetails'
import { useTranslation } from 'react-i18next'
import HelpIcon from '@mui/icons-material/Help'
import { Tooltip } from '@mui/material'
import PhotoModal from './PhotoComposition/PhotoModal'
import { useUserContext } from '@/contexts/UserContext/index.context'
import { UserRole } from '@/types/models/user.type'

type Props = {
  delivery: Delivery
  index?: number
  isAdmin?: boolean
}

const DeliveryDetailsBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: '#c9ccd5',
  display: 'flex',
  gap: theme.spacing(2.5),
  justifyContent: 'space-between',
}))

const DeliveryDetailsSection = ({ delivery, index = 0 }: Props) => {
  const { t } = useTranslation()
  const vehicle = delivery.vehicles[index]
  const { userState } = useUserContext()
  const isAdmin = userState?.['custom:role'] === UserRole.ADMIN
  return (
    <>
      <DeliveryDetailsBox>
        <Helper
          title={
            index === 0
              ? t('delivery.summary.delivered-vehicle-information')
              : t('delivery.summary.restituted-vehicle-information')
          }
        />
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 40px 1fr',
            gap: '16px',
          }}
        >
          <VehicleDetails
            title={t('common.shared.departed')}
            date={index === 0 ? delivery?.departedAt : undefined}
            km={vehicle?.mileageBefore}
            fuel={vehicle?.essenceBefore}
          />
          <MiddleArrow />
          <VehicleDetails
            title={t('common.shared.arrived')}
            date={index === 0 ? delivery?.completedAt : undefined}
            fuel={vehicle?.essenceAfter}
            km={vehicle?.mileageAfter}
          />
        </Box>
      </DeliveryDetailsBox>
      <PhotoModal delivery={delivery} isAdmin={isAdmin} index={index} />
    </>
  )
}

const Helper = ({ title }: { title: string }) => {
  return (
    <Tooltip
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
      }}
      title={title}
      placement='left'
    >
      <HelpIcon color='primary' />
    </Tooltip>
  )
}

export default DeliveryDetailsSection
